import {
  createStylesParam,
  createStylesParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import {
  getCascadingFontDefaultValue,
  isElementVisible,
} from '../../services/style-params';

export type StylesParams = {
  wrapperBackgroundColor: StyleParamType.Color;
  wrapperBorderColor: StyleParamType.Color;
  wrapperBorderWidth: StyleParamType.Number;
  spaceFromTop: StyleParamType.Number;
  spaceOverviewInformation: StyleParamType.Number;
  spaceBetweenSections: StyleParamType.Number;
  titleFont: StyleParamType.Font;
  /** @deprecated Use titleFont */
  titleFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of titleFont */
  titleFontSizeLegacy: StyleParamType.Number;
  titleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  /** @deprecated Use sectionTitleFont */
  sectionTitleFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of sectionTitleFont */
  sectionTitleFontSizeLegacy: StyleParamType.Number;
  sectionTitleColor: StyleParamType.Color;
  sectionDescriptionFont: StyleParamType.Font;
  /** @deprecated Use sectionDescriptionFont */
  sectionDescriptionFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of sectionDescriptionFont */
  sectionDescriptionFontSizeLegacy: StyleParamType.Number;
  sectionDescriptionColor: StyleParamType.Color;
  buttonFont: StyleParamType.Font;
  /** @deprecated Use buttonFont */
  buttonFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of buttonFont */
  buttonFontSizeLegacy: StyleParamType.Number;
  buttonBgColor: StyleParamType.Color;
  buttonBorderColor: StyleParamType.Color;
  textPrimaryColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  fieldLabelFont: StyleParamType.Font;
  /** @deprecated Use fieldLabelFont */
  fieldLabelFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of fieldLabelFont */
  fieldLabelFontSizeLegacy: StyleParamType.Number;
  fieldLabelColor: StyleParamType.Color;
  fieldPlaceholderFont: StyleParamType.Font;
  /** @deprecated Use fieldPlaceholderFont */
  fieldPlaceholderFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of fieldPlaceholderFont */
  fieldPlaceholderFontSizeLegacy: StyleParamType.Number;
  fieldPlaceholderColor: StyleParamType.Color;
  fieldTextFont: StyleParamType.Font;
  /** @deprecated Use fieldTextFont */
  fieldTextFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of fieldTextFont */
  fieldTextFontSizeLegacy: StyleParamType.Number;
  fieldTextColor: StyleParamType.Color;
  badgeTitleFont: StyleParamType.Font;
  /** @deprecated Use badgeTitleFont */
  badgeTitleFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of badgeTitleFont */
  badgeTitleFontSizeLegacy: StyleParamType.Number;
  badgeTitleColor: StyleParamType.Color;
  badgeDescriptionFont: StyleParamType.Font;
  /** @deprecated Use badgeDescriptionFont */
  badgeFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of badgeDescriptionFont */
  badgeFontSizeLegacy: StyleParamType.Number;
  badgeDescriptionColor: StyleParamType.Color;
  blogPostCardWidth: StyleParamType.Number;
  blogPostCardHeight: StyleParamType.Number;
  blogPostCardMargin: StyleParamType.Number;
  blogPostCardBackgroundColor: StyleParamType.Color;
  blogPostCardBorderColor: StyleParamType.Color;
  blogPostCardBorderWidth: StyleParamType.Number;
  blogPostTextPadding: StyleParamType.Number;
  blogPostTitleFont: StyleParamType.Font;
  /** @deprecated Use blogPostTitleFont */
  blogPostTitleFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of blogPostTitleFont */
  blogPostTitleFontSizeLegacy: StyleParamType.Number;
  blogPostTitleColor: StyleParamType.Color;
  blogPostExcerptFont: StyleParamType.Font;
  /** @deprecated Use blogPostExcerptFont */
  blogPostExcerptFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of blogPostExcerptFont */
  blogPostExcerptFontSizeLegacy: StyleParamType.Number;
  blogPostExcerptColor: StyleParamType.Color;
  blogPostHoverColor: StyleParamType.Color;
  emptyStateTitleFont: StyleParamType.Font;
  /** @deprecated Use emptyStateTitleFont */
  emptyStateTitleFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of emptyStateTitleFont */
  emptyStateTitleFontSizeLegacy: StyleParamType.Number;
  emptyStateTitleColor: StyleParamType.Color;
  emptyStateDescriptionFont: StyleParamType.Font;
  /** @deprecated Use emptyStateDescriptionFont */
  emptyStateDescriptionFontLegacy: StyleParamType.Font;
  /** @deprecated Font size is part of emptyStateDescriptionFont */
  emptyStateDescriptionFontSizeLegacy: StyleParamType.Number;
  emptyStateDescriptionColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  buttonBorderWidth: StyleParamType.Number;
  blogPostCornerRadius: StyleParamType.Number;
  showPostsCount: StyleParamType.Boolean;
  showPostCover: StyleParamType.Boolean;
  showPostPublishDate: StyleParamType.Boolean;
  showPostReadingTime: StyleParamType.Boolean;
  showPostExcerpt: StyleParamType.Boolean;
  showPostViewsCounter: StyleParamType.Boolean;
  showPostCommentsCounter: StyleParamType.Boolean;
  showPostLikesCounter: StyleParamType.Boolean;
  showJoinDate: StyleParamType.Boolean;
};

/** @deprecated */
const titleFontLegacy = createStylesParam('text-title-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Page-title', { size: 28 }),
});

/** @deprecated */
const titleFontSizeLegacy = createStylesParam('titleFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
});

const titleFont = createStylesParam('titleFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: titleFontLegacy,
      mobileFontSizeStyleParam: titleFontSizeLegacy,
      defaults: {
        preset: 'Page-title',
        desktopFontSize: 28,
        mobileFontSize: 20,
      },
    });
  },
});

/** @deprecated */
const sectionTitleFontLegacy = createStylesParam('section-title-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Heading-XL', { size: 20 }),
});

/** @deprecated */
const sectionTitleFontSizeLegacy = createStylesParam('sectionTitleFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 16,
});

const sectionTitleFont = createStylesParam('sectionTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: sectionTitleFontLegacy,
      mobileFontSizeStyleParam: sectionTitleFontSizeLegacy,
      defaults: {
        preset: 'Heading-XL',
        desktopFontSize: 20,
        mobileFontSize: 16,
      },
    });
  },
});

/** @deprecated */
const sectionDescriptionFontLegacy = createStylesParam(
  'section-description-font',
  {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
);

/** @deprecated */
const sectionDescriptionFontSizeLegacy = createStylesParam(
  'sectionDescriptionFontSize',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
);

const sectionDescriptionFont = createStylesParam('sectionDescriptionFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: sectionDescriptionFontLegacy,
      mobileFontSizeStyleParam: sectionDescriptionFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 16,
        mobileFontSize: 14,
      },
    });
  },
});

/** @deprecated */
const buttonFontLegacy = createStylesParam('button-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 16 }),
});

/** @deprecated */
const buttonFontSizeLegacy = createStylesParam('buttonFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 16,
});

const buttonFont = createStylesParam('buttonFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: buttonFontLegacy,
      mobileFontSizeStyleParam: buttonFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 16,
        mobileFontSize: 16,
      },
    });
  },
});

const buttonBgColor = createStylesParam('button-opacity-and-color', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
});

/** @deprecated */
const fieldLabelFontLegacy = createStylesParam('field-label-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

/** @deprecated */
const fieldLabelFontSizeLegacy = createStylesParam('fieldLabelFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
});

const fieldLabelFont = createStylesParam('fieldLabelFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: fieldLabelFontLegacy,
      mobileFontSizeStyleParam: fieldLabelFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 14,
        mobileFontSize: 14,
      },
    });
  },
});

/** @deprecated */
const fieldPlaceholderFontLegacy = createStylesParam('field-placeholder-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 16 }),
});

/** @deprecated */
const fieldPlaceholderFontSizeLegacy = createStylesParam(
  'fieldPlaceholderFontSize',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
);

const fieldPlaceholderFont = createStylesParam('fieldPlaceholderFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: fieldPlaceholderFontLegacy,
      mobileFontSizeStyleParam: fieldPlaceholderFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 16,
        mobileFontSize: 16,
      },
    });
  },
});

/** @deprecated */
const fieldTextFontLegacy = createStylesParam('field-text-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 16 }),
});

/** @deprecated */
const fieldTextFontSizeLegacy = createStylesParam('fieldTextFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 16,
});

const fieldTextFont = createStylesParam('fieldTextFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: fieldTextFontLegacy,
      mobileFontSizeStyleParam: fieldTextFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 16,
        mobileFontSize: 16,
      },
    });
  },
});

/** @deprecated */
const badgeTitleFontLegacy = createStylesParam('badge-title-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 16,
    style: { bold: true, italic: false, underline: false },
  }),
});

/** @deprecated */
const badgeTitleFontSizeLegacy = createStylesParam('badgeTitleFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 16,
});

const badgeTitleFont = createStylesParam('badgeTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: badgeTitleFontLegacy,
      mobileFontSizeStyleParam: badgeTitleFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 16,
        mobileFontSize: 16,
        style: { bold: true, italic: false, underline: false },
      },
    });
  },
});

/** @deprecated */
const badgeFontLegacy = createStylesParam('badge-description-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 14 }),
});

/** @deprecated */
const badgeFontSizeLegacy = createStylesParam('badgeFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
});

const badgeDescriptionFont = createStylesParam('badgeDescriptionFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: badgeFontLegacy,
      mobileFontSizeStyleParam: badgeFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 14,
        mobileFontSize: 14,
      },
    });
  },
});

/** @deprecated */
const blogPostTitleFontLegacy = createStylesParam('text-post-title-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Heading-L', { size: 24 }),
});

/** @deprecated */
const blogPostTitleFontSizeLegacy = createStylesParam('blogPostTitleFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
});

const blogPostTitleFont = createStylesParam('blogPostTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: blogPostTitleFontLegacy,
      mobileFontSizeStyleParam: blogPostTitleFontSizeLegacy,
      defaults: {
        preset: 'Heading-L',
        desktopFontSize: 24,
        mobileFontSize: 20,
      },
    });
  },
});

/** @deprecated */
const blogPostExcerptFontLegacy = createStylesParam(
  'text-post-description-font',
  {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 15 }),
  },
);

/** @deprecated */
const blogPostExcerptFontSizeLegacy = createStylesParam(
  'blogPostExcerptFontSize',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
);

const blogPostExcerptFont = createStylesParam('blogPostExcerptFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: blogPostExcerptFontLegacy,
      mobileFontSizeStyleParam: blogPostExcerptFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 15,
        mobileFontSize: 14,
      },
    });
  },
});

/** @deprecated */
const emptyStateTitleFontLegacy = createStylesParam('empty-state-title-font', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 24 }),
});

/** @deprecated */
const emptyStateTitleFontSizeLegacy = createStylesParam(
  'emptyStateTitleFontSize',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
);

const emptyStateTitleFont = createStylesParam('emptyStateTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: (context) => {
    return getCascadingFontDefaultValue({
      context,
      fontStyleParam: emptyStateTitleFontLegacy,
      mobileFontSizeStyleParam: emptyStateTitleFontSizeLegacy,
      defaults: {
        preset: 'Body-M',
        desktopFontSize: 24,
        mobileFontSize: 24,
      },
    });
  },
});

/** @deprecated */
const emptyStateDescriptionFontLegacy = createStylesParam(
  'empty-state-description-font',
  {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
);

/** @deprecated */
const emptyStateDescriptionFontSizeLegacy = createStylesParam(
  'emptyStateDescriptionFontSize',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
);

const emptyStateDescriptionFont = createStylesParam(
  'emptyStateDescriptionFont',
  {
    type: StyleParamType.Font,
    getDefaultValue: (context) => {
      return getCascadingFontDefaultValue({
        context,
        fontStyleParam: emptyStateDescriptionFontLegacy,
        mobileFontSizeStyleParam: emptyStateDescriptionFontSizeLegacy,
        defaults: {
          preset: 'Body-M',
          desktopFontSize: 16,
          mobileFontSize: 16,
        },
      });
    },
  },
);

export default createStylesParams<StylesParams>({
  wrapperBackgroundColor: {
    key: 'box-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  wrapperBorderColor: {
    key: 'border-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  wrapperBorderWidth: {
    key: 'border-width-2',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  spaceFromTop: {
    key: 'space-from-top',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
    inheritDesktop: false,
  },
  spaceOverviewInformation: {
    key: 'space-overview-information',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  spaceBetweenSections: {
    key: 'space-between-sections',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
    inheritDesktop: false,
  },
  titleFont,
  titleFontLegacy,
  titleFontSizeLegacy,
  titleColor: {
    key: 'text-color-title',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont,
  sectionTitleFontLegacy,
  sectionTitleFontSizeLegacy,
  sectionTitleColor: {
    key: 'section-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionDescriptionFont,
  sectionDescriptionFontLegacy,
  sectionDescriptionFontSizeLegacy,
  sectionDescriptionColor: {
    key: 'section-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonFont,
  buttonFontLegacy,
  buttonFontSizeLegacy,
  buttonBgColor,
  buttonBorderColor: {
    key: 'button-border-opacity-and-color',
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(buttonBgColor) ?? wixColorParam('color-8'),
  },
  textPrimaryColor: {
    key: 'text-color-primary',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dividerColor: {
    key: 'dividers-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    key: 'dividers-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  fieldLabelFont,
  fieldLabelFontLegacy,
  fieldLabelFontSizeLegacy,
  fieldLabelColor: {
    key: 'field-label-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  fieldPlaceholderFont,
  fieldPlaceholderFontLegacy,
  fieldPlaceholderFontSizeLegacy,
  fieldPlaceholderColor: {
    key: 'field-placeholder-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  fieldTextFont,
  fieldTextFontLegacy,
  fieldTextFontSizeLegacy,
  fieldTextColor: {
    key: 'field-text-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  badgeTitleFont,
  badgeTitleFontLegacy,
  badgeTitleFontSizeLegacy,
  badgeTitleColor: {
    key: 'badge-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  badgeDescriptionFont,
  badgeFontLegacy,
  badgeFontSizeLegacy,
  badgeDescriptionColor: {
    key: 'badge-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  blogPostCardWidth: {
    key: 'post-list-card-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 288,
  },
  blogPostCardHeight: {
    key: 'post-list-card-height',
    type: StyleParamType.Number,
    getDefaultValue: () => 250,
  },
  blogPostCardMargin: {
    key: 'post-list-card-margin',
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  blogPostCardBackgroundColor: {
    key: 'post-background-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  blogPostCardBorderColor: {
    key: 'post-border-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  blogPostCardBorderWidth: {
    key: 'post-border-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  blogPostTextPadding: {
    key: 'post-list-text-padding',
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  blogPostTitleFont,
  blogPostTitleFontLegacy,
  blogPostTitleFontSizeLegacy,
  blogPostTitleColor: {
    key: 'text-post-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  blogPostExcerptFont,
  blogPostExcerptFontLegacy,
  blogPostExcerptFontSizeLegacy,
  blogPostExcerptColor: {
    key: 'text-post-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  blogPostHoverColor: {
    key: 'text-post-hover-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.7),
  },
  emptyStateTitleFont,
  emptyStateTitleFontLegacy,
  emptyStateTitleFontSizeLegacy,
  emptyStateTitleColor: {
    key: 'empty-state-title-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  emptyStateDescriptionFont,
  emptyStateDescriptionFontLegacy,
  emptyStateDescriptionFontSizeLegacy,
  emptyStateDescriptionColor: {
    key: 'empty-state-description-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonCornerRadius: {
    key: 'button-corner-radius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderWidth: {
    key: 'button-border-width',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  blogPostCornerRadius: {
    key: 'blog-post-corner-radius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  showPostsCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostCover: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostPublishDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostReadingTime: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostExcerpt: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostViewsCounter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostCommentsCounter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showPostLikesCounter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
  showJoinDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined as unknown as boolean,
  },
});

export const customCssVars: CustomCssVarsFn = ({ tpaData, styleParams }) => {
  const isJoinDateVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showJoinDate',
    tpaData,
    tpaDataKey: 'showJoinDate',
  });
  const isPostCountVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostsCount',
    tpaData,
    tpaDataKey: 'showPostListCounter',
    defaultValue: false,
  });
  const isPostCoverVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostCover',
    tpaData,
    tpaDataKey: 'postListShowCoverImage',
  });
  const isPostPublishDateVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostPublishDate',
    tpaData,
    tpaDataKey: 'postListShowPublishDate',
  });
  const isPostReadingTimeVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostReadingTime',
    tpaData,
    tpaDataKey: 'postListShowReadingTime',
  });
  const isPostExcerptVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostExcerpt',
    tpaData,
    tpaDataKey: 'postListShowDescription',
  });
  const isPostViewsCounterVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostViewsCounter',
    tpaData,
    tpaDataKey: 'postListShowViewsCounter',
  });
  const isPostCommentsCounterVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostCommentsCounter',
    tpaData,
    tpaDataKey: 'postListShowCommentsCounter',
  });
  const isPostLikesCounterVisible = isElementVisible({
    styleParams,
    styleParamsKey: 'showPostLikesCounter',
    tpaData,
    tpaDataKey: 'postListShowLikesCounter',
  });

  const isPostDateBarVisible =
    isPostPublishDateVisible || isPostReadingTimeVisible;

  const isPostDateBarSeparatorVisible =
    isPostPublishDateVisible && isPostReadingTimeVisible;

  const isPostFooterVisible =
    isPostViewsCounterVisible ||
    isPostCommentsCounterVisible ||
    isPostLikesCounterVisible;

  return {
    createdDateDisplay: isJoinDateVisible ? 'block' : 'none',
    postsCountDisplay: isPostCountVisible ? 'initial' : 'none',
    postCoverDisplay: isPostCoverVisible ? 'flex' : 'none',
    postDateBarDisplay: isPostDateBarVisible ? 'block' : 'none',
    postPublishDateDisplay: isPostPublishDateVisible ? 'inline' : 'none',
    postReadingTimeDisplay: isPostReadingTimeVisible ? 'inline' : 'none',
    postDateBarSeparatorDisplay: isPostDateBarSeparatorVisible
      ? 'inline'
      : 'none',
    postExcerptDisplay: isPostExcerptVisible ? '-webkit-box' : 'none',
    postFooterDisplay: isPostFooterVisible ? 'block' : 'none',
    postViewsCounterDisplay: isPostViewsCounterVisible ? 'inline-flex' : 'none',
    postCommentsCounterDisplay: isPostCommentsCounterVisible
      ? 'inline-flex'
      : 'none',
    postLikesCounterDisplay: isPostLikesCounterVisible ? 'inline-flex' : 'none',
  };
};
